<template>
    <div>
        <div class="title">
            提车信息
        </div>
       
        <div class="imgWarper">
            <div class="box">
            <!-- border="1" cellspacing=0 -->
            <table class="table_box" width="100%" cellspacing=0  align="center">
                <tr>
                    <th>资料物品</th>
                    <th>数量</th>
                    <th >实收数量</th>
                    <th>缺失备注</th>
                </tr>
                <tr align="center" v-for="(item,index) in tableData" :key="index">
                    <td>{{item.short_name}}</td>
                    <td>{{item.num}}</td>
                    <td > 
                    
                        <van-field v-model="item.realNum" oninput="value=value.replace(/[^\d]/g,'')" type="number" placeholder="请填写" />
                    </td>
                    <td  v-if="status == 'isAudit'"> <van-field v-model="item.receiptsNum" oninput="value=value.replace(/[^\d]/g,'')" type="number"  placeholder="请填写" /></td>
                    <td> <van-field v-model="item.remark" placeholder="请填写"  /></td>
                </tr>
            </table>
            <div class="liImage">
                <div>
                    请交车人签字：
                    <br>
                    <van-button type="warning" @click="chu">清除</van-button>
                    <br>
                    <div v-if="qianming" class="img"> 
                        <van-image  :src="qianming" />
                    </div>
                    <div  v-if="!qianming">
                        <signature ref="tureRef" @isFile="isFile"  />
                    </div>
                   <!-- <signature ref="tureRef" @isFile="isFile" /> -->
                </div>
            </div>
            <div>
                <van-button  type="primary" size="large" @click="submit">提交</van-button>
               
            </div>
        </div>

        </div>
    </div>
</template>

<script>
import UploadImg from '@/components/UploadImg'
import signature from '@/components/signature'
import wx from 'weixin-js-sdk'
    export default {
        props:['status'],
        components: {
            UploadImg,signature
        },
        data() {
            return {
                value:'',
                isbase64:"",
                record:"",
                end_position:"",
                tableData:[],
                pageId:"",
                phone:"",
                contact_person:"",
                qianming:'',
            }
        },
        mounted () {
            this.getInfo();
        },
        methods: {
             //清除画布签名
             chu(){
                if(this.qianming){
                    this.qianming = ''
                }else{
                    this.$refs.tureRef.clearSignImg()
                }
            },
           
            async getInfo() {
                try{
                   
                    this.record = ''
                    this.end_position = ''
                    this.$nextTick(()=>{

                        this.$refs.business_license.imagesUrlList = []
                        this.$refs.business_license.images = []
                        this.$refs.tureRef.clearSignImg()
                    })
                    const {data} = await this.$http.post('index.php/api/check_information_find',{id:this.$route.query.id}).then(res=>res)
                    if(data.code == 0) {

                        this.tableData = data.data.check_information
                        this.qianming = data.data.picker_signature
                    }else{
                        this.$toast.fail(data.msg)
                    }
                        
                }
                catch{}
            },
            async isFile(e){
                let params = new FormData(); //创建form对象
                    params.append("file", e);
                    this.$http.post("/index.php/index/web_upload/index", params).then((res) => {
                        if(res.data.code == 0){
                            this.obsubmit(res.data.data)
                            
                        }else{
                            this.$toast.fail(res.data.msg)
                        }
                    })
            },
            obsubmit(img){
                let obj = {
                    id:this.$route.query.id,
                    picker_accept_check_information:this.tableData,
                    picker_signature:img,
                }
                this.$http.post("/index.php/api/check_information_submit", obj).then((res) => {
                    if(res.data.code == 0){
                        this.$toast.success(res.data.msg)
                        wx.closeWindow()
                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                })
            },
           submit(){
              
                if(this.qianming){
                    this.obsubmit(this.qianming)
                }else{this.$refs.tureRef.saveSignImg('NoJY')}
            },
           
        },
    }
</script>

<style lang="scss" scoped>
.title{
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    text-align: center;
}
.imgWarper{
    margin-top: 1rem;
}
.box{
    padding: 10px;
    .table_box{
        border-right:1px solid #000;border-bottom:1px solid #000;
        tr{
            display: flex;
        }
        td{
            border-left:1px solid #000;
            border-top:1px solid #000;
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        th{border-left:1px solid #000;border-top:1px solid #000; flex:1;}
       
    }
}
.van-cell{
    padding: 0;
}
.liImage{
    margin-top: 20px;
}
.img{
    background: #eee;
    position: relative;
}
</style>