<template>
    <div>
        <div class="sign">
            <!-- <p class="sign-title">建议您调整手机，横向进行签名！</p> -->
            <div class="btnList" ref="btnList">
                <!-- <van-button type="danger" v-throttle size="small" @click="clearSignImg">清空</van-button> -->
                <!-- <el-button type="primary" v-throttle size="small" class="ml30 mr30" @click="saveSignImg">保存</el-button> -->
                <!-- <van-button type="primary" v-throttle size="small" @click="saveSignImg">提交</van-button> -->
            </div>

            <sign-canvas
                class="sign-canvas"
                ref="SignCanvas"
                :options="options"
                v-model="value"
            />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                options: {
                    lastWriteSpeed: 1, //书写速度 [Number] 可选
                    lastWriteWidth: 2, //下笔的宽度 [Number] 可选
                    lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
                    lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
                    canvasWidth: document.documentElement.clientWidth - 15, //canvas宽高 [Number] 可选
                    canvasHeight: "200", //高度  [Number] 可选
                    isShowBorder: false, //是否显示边框 [可选]
                    bgColor: "#eee", //背景色 [String] 可选
                    borderWidth: 1, // 网格线宽度  [Number] 可选
                    borderColor: "#ff787f", //网格颜色  [String] 可选
                    writeWidth: 3, //基础轨迹宽度  [Number] 可选
                    maxWriteWidth: 50, // 写字模式最大线宽  [Number] 可选
                    minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
                    writeColor: "#101010", // 轨迹颜色  [String] 可选
                    isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                    imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
                },
                value:'',
                isFile:{},
                isType:''
            }
        },
        methods: {
            //清楚
            clearSignImg() {
                this.$refs.SignCanvas.canvasClear();
            },
            //提交
            saveSignImg(type){
                this.isType = type?type:undefined
                if (this.value == null) {
                    this.$toast.fail('请先签字')
                } else {
                    this.rotateBase64Img(this.value, 0,this.callback)
                }
            },
            //吧照片旋转过来
            rotateBase64Img(src, edg, callback) {
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    var imgW;//图片宽度
                    var imgH;//图片高度
                    var size;//canvas初始大小
                    if (edg % 90 != 0) {
                    console.error("旋转角度必须是90的倍数!");
                               throw '旋转角度必须是90的倍数!';
                        }
                    (edg < 0) && (edg = (edg % 360) + 360)
                    const quadrant = (edg / 90) % 4; //旋转象限
                    const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
                     var image = new Image();
                    image.crossOrigin = "anonymous"
                     image.src = src;
                     image.onload = function () {
                            imgW = image.width;
                            imgH = image.height;
                            size = imgW > imgH ? imgW : imgH;
                            canvas.width = size * 2;
                            canvas.height = size * 2;
                        switch (quadrant) {
                            case 0:
                                cutCoor.sx = size;
                                cutCoor.sy = size;
                                cutCoor.ex = size + imgW;
                                cutCoor.ey = size + imgH;
                                break;
                            case 1:
                                cutCoor.sx = size - imgH;
                                cutCoor.sy = size;
                                cutCoor.ex = size;
                                cutCoor.ey = size + imgW;
                                break;
                            case 2:
                                    cutCoor.sx = size - imgW;
                                    cutCoor.sy = size - imgH;
                                    cutCoor.ex = size;
                                    cutCoor.ey = size;
                                break;
                            case 3:
                                cutCoor.sx = size;
                                cutCoor.sy = size - imgW;
                                cutCoor.ex = size + imgH;
                                cutCoor.ey = size + imgW;
                                break;
                    }
                    ctx.translate(size, size);
                    ctx.rotate(edg * Math.PI / 180);
                    ctx.drawImage(image, 0, 0);
                            var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
                        if (quadrant % 2 == 0) {
                                canvas.width = imgW;
                                    canvas.height = imgH;
                        } else {
                        canvas.width = imgH;
                            canvas.height = imgW;
                        }
                      ctx.putImageData(imgData, 0, 0);
                          callback(canvas.toDataURL())
                     };
            },
            //上传
            callback(base64data) {
                let a = this.base64toFile(base64data) //将base64转换成file流文件进行上传
              
                if(this.isType){
                    this.$emit('isFile',a)
                    this.isType = ''
                }else{

                    let params = new FormData(); //创建form对象
                     params.append("file", a);
                     this.$http.post("/index.php/index/upload/index", params).then((res) => {
                         if(res.data.code == 0){
                             // this.obsubmit(res.data.data)
                             this.$emit('isFile',res.data.data)
                         }
                     })
                }

            },
            //base64z转换file
            base64toFile (dataurl, filename = 'file') {
                       let arr = dataurl.split(',')
                      let mime = arr[0].match(/:(.*?);/)[1]
                       let suffix = mime.split('/')[1]
                       let bstr = atob(arr[1])
                       let n = bstr.length
                        let u8arr = new Uint8Array(n)
                      while (n--) {
                           u8arr[n] = bstr.charCodeAt(n)
                           }
                     return new File([u8arr], `${filename}.${suffix}`, {
                        type: mime
                   })
             },
        },
    }
</script>

<style lang="scss" scoped>

</style>